import React, { Component } from "react";
import Navbar from "../components/navbar";
import AniLink from "gatsby-plugin-transition-link/AniLink";

class Projets extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef(); 
        this.scroll =null;
    }

    componentDidMount(){
        this.scrollRef = React.createRef(); 

        import("locomotive-scroll").then(locomotiveModule => {
            this.scroll = new locomotiveModule.default({
              el: this.scrollRef.current,
              smooth: true,
              smartphone: {
                smooth: true,
                direction: 'horizontal'
            },
            tablet: {
                smooth: true,
                direction: 'horizontal'
            },
              direction: 'horizontal'
            })
          })
    }

    componentWillUnmount(){
        this.scroll.destroy();
    }

    render(){
        return (
            <React.Fragment>
                <Navbar color="orange"/>
                <div data-scroll-container ref={this.scrollRef} style={{overflow:"hidden"}}>
                    <div className="content">
                        <div className="gallery">
                            <div id="title_projets" className="gallery__text"><span className="gallery__text-inner" data-scroll data-scroll-speed="3" data-scroll-direction="vertical">Nos</span><span data-scroll data-scroll-speed="-4" data-scroll-direction="vertical" className="gallery__text-inner">projets</span></div>
                            <figure className="gallery__item" data-scroll data-scroll-speed="2" data-scroll-direction="vertical">
                                <div className="gallery__item-img"><div className="gallery__item-imginner" data-scroll data-scroll-speed="1" data-scroll-direction="vertical" id="ads"></div></div>
                                <figcaption className="gallery__item-caption">
                                    <h2 className="gallery__item-title" data-scroll data-scroll-speed="1" data-scroll>ADS</h2>
                                    <span className="gallery__item-number">01</span>
                                    <AniLink paintDrip color="purple" to="/projets/ads/" className="gallery__item-link" >Découvrir</AniLink>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item" data-scroll data-scroll-speed="-2" data-scroll-direction="vertical">
                                <div className="gallery__item-img"><div className="gallery__item-imginner" data-scroll data-scroll-speed="1" data-scroll-direction="vertical" id="idsucre"></div></div>
                                <figcaption className="gallery__item-caption">
                                    <h2 className="gallery__item-title" data-scroll data-scroll-speed="1">ID-Sucré</h2>
                                    <span className="gallery__item-number">02</span>
                                    <AniLink paintDrip color="purple" to="/projets/idsucre/" className="gallery__item-link">Découvrir</AniLink>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item" data-scroll data-scroll-speed="2" data-scroll-direction="vertical">
                                <div className="gallery__item-img" ><div className="gallery__item-imginner" data-scroll data-scroll-speed="1" data-scroll-direction="vertical" id="qwaris"></div></div>
                                <figcaption className="gallery__item-caption">
                                    <h2 className="gallery__item-title" data-scroll data-scroll-speed="1">Qwaris</h2>
                                    <span className="gallery__item-number">03</span>
                                    <AniLink paintDrip color="purple" to="/projets/qwaris/" className="gallery__item-link">Découvrir</AniLink>
                                </figcaption>
                            </figure>
                            <div id="projet_fin" className="gallery__text">
                                <span className="gallery__text-inner" data-scroll data-scroll-speed="-4" data-scroll-direction="vertical">Soyez les</span>
                                <span data-scroll data-scroll-speed="3" data-scroll-direction="vertical" className="gallery__text-inner">prochains !</span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>   
        )
    }
}

export default Projets;
